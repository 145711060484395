const CURRENT_TOKEN_KEY = 'beneT2024';
const CURRENT_CLAVE_TOKEN_KEY = 'beneClaveT2024';
const CURRENT_CLAVE_TOKEN_EXPIRATION_KEY = 'beneClaveTExp2024';
const CURRENT_ID_TOKEN_KEY = 'beneIdT2024';
const CURRENT_REFRESH_KEY = 'beneR2024';
const CURRENT_USER_KEY = 'beneU2024';
const CURRENT_ERROR_KEY = 'error2024';
const CURRENT_LANGUAGE_KEY = 'beneLanguage2024';
const COOKIES_MODAL = 'beneCkM2024';
const KEEP_SESSION = 'beneKeepSession';
const REFRESH_TOKEN_URL = 'account-api/v1/public/refresh';
const NOTIFICATIONS_COUNT = 'beneNot2024';
const CURRENT_PETITON_STATUS_KEY = 'cbbPS2024';
const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
const URL_PATTERN = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g; // eslint-disable-line
const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~+=]).{8,}$/
const FILTERS = 'beneFilters2024';
const PHONE_PATTERN = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const ROUTES_LIST = [
  "/",
  "/login",
  // "/signup",
  // "/signup/success",
  "/info",
  "/conditions-info",
  "/cookies",
  "/privacy",
  "/reset-password",
  "/reset-password/success",
  "/reset-password/code",
  "/reset-password/code/success",
  "/profile",
  "/information",
  "/profile/language",
  "/beneficiary/join/request-info",
  "/representative/join/request-info",
  "/beneficiary/join/clave-verification",
  "/representative/join/clave-verification",
  "/beneficiary/join/automated-queries",
  "/representative/join/automated-queries",
  "/representative/join/representative-data",
  "/beneficiary/join/beneficiary-data",
  "/representative/join/beneficiary-data",
  "/beneficiary/join/card-selector",
  "/representative/join/card-selector",
  "/beneficiary/join/notifications-address",
  "/representative/join/notifications-address",
  "/beneficiary/join/upload-documentation",
  "/representative/join/upload-documentation",
  "/beneficiary/join/sign-request",
  "/representative/join/sign-request",
  "/beneficiary/join/leave",
  "/representative/join/leave",
  "/shops",
  "/online-shops",
  "/entidades-adheridas",
  "/csv-search",
  "/notifications"
]

const MENU_BUTTONS = [
  {
    name: 'profile',
    route: '/profile',
    icon: '/misc/icons/menu/profile-menu.png',
    text: 'Mi perfil',
    icon_active: '/misc/icons/menu/profile-menu.png'
  },
  {
    name: 'change-language',
    route: '/profile/sso/language',
    icon: '/misc/icons/menu/profile-menu.png',
    text: 'Cambiar idioma',
    icon_active: '/misc/icons/menu/profile-menu.png'
  },
  {
    name: 'how-works',
    route: '/how-works',
    icon: '/misc/icons/menu/info-menu.png',
    text: '¿Cómo funciona mi bono?',
    icon_active: '/misc/icons/menu/info-menu.png'
  },
  {
    name: 'petitions',
    route: '/petitions',
    icon: '/misc/icons/menu/petitions-menu.svg',
    text: 'Mis solicitudes',
    icon_active: '/misc/icons/menu/petitions-menu.svg'
  },
  {
    name: 'petitions-sso',
    route: '/petitions-sso',
    icon: '/misc/icons/menu/petitions-menu.svg',
    text: 'Mis solicitudes',
    icon_active: '/misc/icons/menu/petitions-menu.svg'
  },
  // {
  //   name: 'representations',
  //   route: '/representations',
  //   icon: '/misc/icons/menu/cards-menu.png',
  //   text: 'Mis representaciones',
  //   icon_active: '/misc/icons/menu/cards-menu.png'
  // },
  {
    name: 'notifications',
    route: '/notifications',
    icon: '/misc/icons/menu/notifications-menu.png',
    text: 'Mis avisos',
    icon_active: '/misc/icons/menu/notifications-menu.png'
  },
  {
    name: 'shops',
    route: '/shops',
    icon: '/misc/icons/menu/shops-menu.png',
    text: 'Entidades Adheridas',
    icon_active: '/misc/icons/menu/shops-menu.png',
    submenus: [
      {
        name: 'shops',
        route: '/shops',
        text: 'phisicalShopPoints',
      },
      {
        name: 'online-shops',
        route: '/online-shops',
        text: 'onlineShopPoints',
      }
    ],
  },
  {
    name: 'information',
    route: '/information',
    icon: '/misc/icons/menu/info-menu.png',
    text: 'Información',
    icon_active: '/misc/icons/menu/info-menu.png'
  },
  {
    name: 'logout',
    route: '/logout',
    icon: '/misc/icons/menu/Close@3x.png',
    text: 'logout',
    icon_active: '/misc/icons/menu/Close@3x.png'
  }
];

const getClaveHtml = (action, saml, relay) => {
  return (
    `
      <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="es" lang="es">
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <script type="text/javascript">
              function loginClave() {
                document.getElementById('loginClave').submit();
              }
            </script>
          </head>
        <body onload="loginClave()">
          <form id="loginClave" action="${action}" method="post">
            <input type="hidden" name="SAMLRequest" value="${saml}"/>
            <input type="hidden" name="RelayState" value="${relay}"/>
          </form>
        </body>
      </html>
    `
  )
}

const USERS_WHITE_LIST = []

export default {
  CURRENT_TOKEN_KEY,
  CURRENT_CLAVE_TOKEN_KEY,
  CURRENT_CLAVE_TOKEN_EXPIRATION_KEY,
  CURRENT_ID_TOKEN_KEY,
  CURRENT_REFRESH_KEY,
  CURRENT_USER_KEY,
  CURRENT_PETITON_STATUS_KEY,
  CURRENT_ERROR_KEY,
  CURRENT_LANGUAGE_KEY,
  EMAIL_PATTERN,
  MENU_BUTTONS,
  URL_PATTERN,
  PASSWORD_PATTERN,
  FILTERS,
  COOKIES_MODAL,
  KEEP_SESSION,
  REFRESH_TOKEN_URL,
  ROUTES_LIST,
  getClaveHtml,
  PHONE_PATTERN,
  NOTIFICATIONS_COUNT,
  USERS_WHITE_LIST,
};
